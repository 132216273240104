import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import TrackerHero from '../components/tracker/TrackerHero'
import TrackerSection from '../components/tracker/TrackerSection'
import TrackerPoints from '../components/tracker/TrackerPoints'
import TrackerAction from '../components/tracker/TrackerAction'
import TrackerTechnicalPoints1 from '../components/tracker/TrackerTechnicalPoints1'
import TrackerMap from '../components/tracker/TrackerMap'
import TrackerCaseStudy from '../components/tracker/TrackerCaseStudy'
import TrackerCaseStudyIntro from '../components/tracker/TrackerCaseStudyIntro'
import TrackerCaseStudyChallenge from '../components/tracker/TrackerCaseStudyChallenge'
import TrackerCaseStudySolution from '../components/tracker/TrackerCaseStudySolution'
import CallToAction from '../components/tracker/CallToAction'

export const query = graphql`
  query FlameTracker {
    dataJson {
        trackers {
        flame {
          id
          name
          title
          slug
          icon {
            publicURL
          }
          hero {
            title
            subtitle
            image {
            childImageSharp {
              fluid(maxWidth: 800, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
            }
          }
            backgroundImage {
              childImageSharp {
                fluid(maxWidth: 800, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          points {
            id
            text
          }
          action {
            title
            subtitle
            points {
              id
              text
            }
          }
          techincalPoints1 {
            title
            points {
              id
              text
            }
            image {
              childImageSharp {
                fluid(maxWidth: 800, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          map {
            publicURL
          }
          caseStudy {
            title
            clientCase1
            image {
              childImageSharp {
                fluid(maxWidth: 800, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            challenge
            solution
          }
        }
      }
    }
  }
  `

export default function FlameTracker({ data: { dataJson: { trackers: { flame } } } }) {
  return (
    <Layout title='Flame Tracker' url='/flame-tracker' page='flame-tracker'>
      <TrackerHero hero={flame.hero} title={flame.title} icon={flame.icon} />
      <TrackerSection>
        <TrackerPoints tracker={flame} />
        <TrackerAction tracker={flame} />
        <TrackerTechnicalPoints1 techincalPoints={flame.techincalPoints1} />
        <TrackerMap tracker={flame} />
      </TrackerSection>
      <TrackerCaseStudy tracker={flame}>
        <TrackerCaseStudyIntro tracker={flame} />
        <TrackerCaseStudyChallenge tracker={flame} />
        <TrackerCaseStudySolution tracker={flame} />
      </TrackerCaseStudy>
      <CallToAction />
    </Layout>
  )
}
